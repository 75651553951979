import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {MessageService} from 'primeng/components/common/messageservice';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'environments/environment';

class Erro {
  constructor(public tipo: string, public mensagem: string) {}
}

@Injectable()
export class ApiErrorHandlerService {

  constructor(
    private messageService: MessageService) { }

  handle() {
    return (requestError: HttpErrorResponse): Observable<any> => {

      if (!environment.production) {
        console.error('requestError', requestError);
      }

      const erros = this.recuperarErros(requestError);
      erros.forEach(erro => {
        this.messageService.add({
          severity:'error',
          summary: erro.tipo,
          detail: erro.mensagem});
      });

      return Observable.throw(requestError);
    };
  }

  private recuperarErros(requestError: HttpErrorResponse): Erro[] {
    if (this.isErrorSegurancaJaTratadoNoIntercepor(requestError.status)) {
      return [];
    }

    switch (requestError.status) {
      case 409: return this.conflict(requestError);
      default: return this.other(requestError);
    }
  }

  private isErrorSegurancaJaTratadoNoIntercepor(status: number) {
    return status === 401 || status === 403;
  }

  private  other(requestError: HttpErrorResponse): Erro[] {
    const errors = [];
    if (requestError.error instanceof Blob) {// em caso de erro ao gerar relatorio
      this.handleBlobError(requestError, errors);
      return [];
    }
    else{
      try {
        // para cada erro, obter atributo 'error_description'
        requestError.error.forEach(element => {
            this.obterErroRest(element, errors);
        });
      } catch (e) {
        if (!environment.production) {
          console.error('Translating Errors:', e);
        }
      }
      if (errors.length === 0) {
        errors.push(new Erro('Ocorreu um erro inesperado.',
            'Por favor, tente novamente mais tarde. Se o erro persistir, contate o suporte do sistema.'));
      }
      return errors;
    }

  }

  //quando ha erros em gerar relatorio precisa tratar o retorno como blob
  private handleBlobError(requestError: HttpErrorResponse, errors:Erro[] ){
    const reader = new FileReader();
    reader.onload = () => {
        try {
          const errorJson = JSON.parse(reader.result as string);
          if (errorJson[0] != undefined||  errorJson[0].hasOwnProperty('mensagensErro')) {
              errorJson[0].mensagensErro.forEach(element => {
              errors.push(new Erro('Erro', element));
            });
          }
          if (errors.length === 0) {
            errors.push(new Erro('Ocorreu um erro inesperado.',
                'Por favor, tente novamente mais tarde. Se o erro persistir, contate o suporte do sistema.'));
          }
          errors.forEach(erro => {
          this.messageService.add({
            severity:'error',
            summary: erro.tipo,
            detail: erro.mensagem});
        });
        } catch (e) {
          console.log('Erro ao ler a resposta do erro', e);
        }
    };
    reader.onerror = () => {
      console.log('Erro ao ler a resposta do erro');
    };
    console.log("Sequencia de erros 3.1")
    reader.readAsText(requestError.error);

  }

  private obterErroRest(erro: any, errors: any[]) {
    if (erro.hasOwnProperty('mensagensErro')) {
      erro.mensagensErro.forEach(element => {
        errors.push(new Erro('Erro', element));
      });
    }
  }

  private conflict(requestError: any): Erro[] {
    return [new Erro('Conflito', 'Esse recurso já foi atualizado por outra transação.')];
  }

}
