<div class="anuncio br-card d-flex flex-wrap " [ngClass]="getClasseAnuncioPrivadoOuGoverno()"
  [class.anuncio-meus-interesses]="origemMeusAnunciosInteresse()" (click)="clicouAnuncio()">

  <div class="foto">
    <p-progressSpinner *ngIf="carregandoFoto" [style]="{width: '30px', height: '30px'}" strokeWidth="8"
      style="margin-left: 5px"></p-progressSpinner>
    <img *ngIf="!carregandoFoto" [style.zoom]="zoomFotoCapa" [src]="fotoCapa" loading="lazy" #fotoCapaImg (load)="obterDimensoes(fotoCapaImg)"/>
  </div>

  <div class="content">
    <div class="titulo"><a href="javascript:void(0)" (click)="clicouAnuncio()" [title]="anuncio.titulo">{{anuncio.titulo}}</a></div>
    <div class="d-flex flex-wrap pt-2 pb-2">
      <div *ngIf="anuncioUtil.anuncioGoverno(anuncio)"  class="governo mr-2 mt-1"><span class="br-tag small governo">Governo</span></div>
      <div *ngIf="anuncioUtil.anuncioPrivado(anuncio)" class="privado mr-2 mt-1"><span class="br-tag small privado">Privado</span></div>
      <div *ngIf="exibirStatusAnuncio()" class="situacao mr-2 mt-1"><span class="br-tag small situacao">{{anuncio.anuncioSituacao.nome}}</span>
    </div>

    </div>
    <div class="col-12 d-flex flex-wrap info">
      <div class="col-12 d-flex flex-wrap pt-2 pb-3" *ngIf="exibirQuantidadesUnidades()">
        <div class="col-6"> Qtde: {{anuncio.quantidade}}</div>
        <div class="col-6 text-right">{{anuncio?.unidadeFornecimento?.nome}}</div>
      </div>

      <div class="col-6">{{anuncio?.municipio.nome}} - {{anuncio?.municipio.uf.sigla}}</div>
      <div class="col-6 text-right">{{anuncioUtil.dataAnuncio(anuncio)}}</div>
      <div class="col-12" *ngIf="origemMeusAnunciosInteresse()">Interesse(s): {{anuncio.qtdInteresseMeuAnuncio}}</div>
    </div>
    <div class="footer col-12 pt-3 align-self-end">
      <p-fieldset legend="Ações" *ngIf="exibirBotoesAlterarInteresse() || exibirBotoesAlterarInteresse() ||
      exibirBotaoAtenderComplementacao() || exibirBotaoAlterarAnuncio() || exibirBotaoExcluir() || exibirBotaoInativar()">
        <div class="d-flex justify-content-end">
          <button pButton type="button" title="Editar Interesse" icon="fa fa-edit" (click)="alterarInteresse($event)"
            *ngIf="exibirBotoesAlterarInteresse()"></button>
          <button pButton type="button" title="Cancelar Interesse" icon="fa fa-trash" (click)="cancelarInteresse($event)"
            *ngIf="exibirBotoesAlterarInteresse()"></button>
          <button pButton type="button" title="Atender Complementação" icon="fa fa-exclamation"
            (click)="atenderComplementacao($event)" *ngIf="exibirBotaoAtenderComplementacao()"></button>
          <button pButton type="button" title="Editar Anúncio" icon="fa fa-edit" (click)="alterarAnuncio($event)"
            *ngIf="exibirBotaoAlterarAnuncio()"></button>
          <button pButton type="button" title="Excluir Anúncio" icon="fa fa-trash" (click)="excluirAnuncio($event)"
            *ngIf="exibirBotaoExcluir()"></button>
          <button pButton type="button" title="Inativar Anúncio" icon="fa fa-archive" (click)="inativarAnuncio($event)"
            *ngIf="exibirBotaoInativar()"></button>
        </div>
      </p-fieldset>
      <div class="numero col-12 text-right pt-1">Anúncio: {{anuncio.id}}</div>
    </div>
  </div>

</div>

<p-confirmDialog key="modal_{{anuncio.id}}" header="Confirmação" width="425" #cd>
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="Não" (click)="cd.reject()"
      class="ui-button-secondary"></button>
    <button type="button" pButton icon="pi pi-check" label="Sim" (click)="cd.accept()"
      class="ui-button-success"></button>
  </p-footer>
</p-confirmDialog>
