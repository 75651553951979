import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnuncioService } from '../../services/anuncio.service';
import { ResultadoPesquisaInternaAnuncio } from '../../models/resultado-pesquisa-anuncio.model';
import { ParametrosMeusInteresses } from '../../models/parametros-meus-interesses.model';
import { AnunciosInternoLista } from '../common/anuncios-interno-lista';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { ChaveValor } from 'app/anuncio/models/comuns';

@Component({
  selector: 'reuse-meus-interesses-lista',
  templateUrl: './meus-interesses-lista.component.html',
  styleUrls: ['./meus-interesses-lista.component.scss']
})
export class MeusInteressesListaComponent extends AnunciosInternoLista implements OnInit {

  MEUS_INTERESSES = OrigemEnum.MEUS_INTERESSES;
  parametrosExibir: string = '';
  parametrosExibirObject: ChaveValor[] =[];
  // indicador para verificar se os dados do backend já foram recebidos.
  carregouDados: boolean = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private anuncioService: AnuncioService) {
      super();
    }

  ngOnInit() {
    this.monitorarUrl();
    this.montarOpcoesOrdenacao();
    this.tamanhoPagina = 18;
  }

  /**
   * Monitora a url, sempre que houver mudanças nos parâmetros, realiza nova consulta de anúncios.
   */
  monitorarUrl() {
    this.route.queryParams.subscribe(
      params => {
        const parametros: ParametrosMeusInteresses = this.anuncioService.obterParametrosMeusInteressesUrl(params);
        this.ordem = parametros.ordem;
        this.montaExibirParametros(parametros);
        this.anuncioService.consultarMeusInteresses(parametros).subscribe(
          (resultado: ResultadoPesquisaInternaAnuncio) => {
            this.anuncios = resultado.anuncios;
            this.totalRegistros = resultado.totalRegistros;
            this.inicio = resultado.inicio;
            this.carregouDados = true;
            window.scrollTo(0,0);
          });
      });
  }

  montaExibirParametros(parametros: ParametrosMeusInteresses) {
    this.parametrosExibir = '';
    this.parametrosExibirObject = [];
    if (parametros.pesquisa) {
      this.parametrosExibir += 'Pesquisa: ' + parametros.pesquisa + ' ';
      this.parametrosExibirObject.push({chave: 'Pesquisa', valor: parametros.pesquisa});
    }
    if (parametros.materialTipos && parametros.materialTipos.length > 0) {
      this.parametrosExibir += 'Tipos: ' + parametros.materialTipos;
      this.parametrosExibirObject.push({chave: 'Tipos', valor: parametros.materialTipos});
    }
    if (parametros.materialCategorias && parametros.materialCategorias.length > 0) {
      this.parametrosExibir += 'Categorias: ' + parametros.materialCategorias;
      this.parametrosExibirObject.push({chave: 'Categorias', valor: parametros.materialCategorias});
    }
    if (parametros.anuncioInteresseSituacoes && parametros.anuncioInteresseSituacoes.length > 0) {
      this.parametrosExibir += 'Situações: ' + parametros.anuncioInteresseSituacoes;
      this.parametrosExibirObject.push({chave: 'Situações', valor: parametros.anuncioInteresseSituacoes});
    }

    if (parametros.periodoInclusao) {
      this.parametrosExibir += 'Período de Inclusão: ' + parametros.periodoInclusao + ' ';
      this.parametrosExibirObject.push({chave: 'Período de Inclusão', valor: parametros.periodoInclusao});
    }
    if (parametros.periodoInteresse) {
      this.parametrosExibir += 'Período de Interesse: ' + parametros.periodoInteresse + ' ';
      this.parametrosExibirObject.push({chave: 'Período de Interesse', valor: parametros.periodoInteresse});
    }
    if (parametros.anuncios && parametros.anuncios.length > 0) {
      this.parametrosExibir += 'Anúncios: ' + parametros.anuncios;
      this.parametrosExibirObject.push({chave: 'Anúncios', valor: parametros.anuncios});
    }
    if (parametros.entidades && parametros.entidades.length > 0) {
      this.parametrosExibir += 'Entidades: ' + parametros.entidades;
      this.parametrosExibirObject.push({chave: 'Entidades', valor: parametros.entidades});
    }
  }

  /**
   * Consulta os meus anúncios, utilizando os parâmetros/filtros da url.
   */
  consultarAnunciosComParametrosUrl() {
    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosMeusInteresses = this.anuncioService.obterParametrosMeusInteressesUrl(parametrosUrl);

    this.router.navigate([OrigemEnum.MEUS_INTERESSES], {
      queryParams: {
        pesquisa: parametros.pesquisa,
        materialTipos: parametros.materialTipos,
        materialCategorias: parametros.materialCategorias,
        periodoInclusao: parametros.periodoInclusao,
        periodoInteresse: parametros.periodoInteresse,
        anuncios: parametros.anuncios,
        entidades: parametros.entidades,
        inicio: this.inicio,
        ordem: this.ordem
      }
    });
  }

}
