import { ConfirmarDoacaoPageComponent } from './components/confirmar-doacao-page/confirmar-doacao-page.component';
import { ConfirmarDoacaoListaComponent } from './components/confirmar-doacao-lista/confirmar-doacao-lista.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnuncioItemComponent } from './components/anuncio-item/anuncio-item.component';
import { AppPrimengModule } from '../app-primeng.module';
import { AnuncioFilterComponent } from './components/anuncio-filter/anuncio-filter.component';
import { AnuncioListPageComponent } from './components/anuncio-list-page/anuncio-list-page.component';
import { AnuncioRoutingModule } from './anuncio-routing.module';
import { AnuncioDetailPageComponent } from './components/anuncio-detail-page/anuncio-detail-page.component';
import { MeusInteressesPageComponent } from './components/meus-interesses-page/meus-interesses-page.component';
import { MeusInteressesListaComponent } from './components/meus-interesses-lista/meus-interesses-lista.component';
import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { TenhoInteresseComponent } from './components/tenho-interesse/tenho-interesse.component';
import { MeusAnunciosInteressePageComponent } from './components/meus-anuncios-interesse-page/meus-anuncios-interesse-page.component';
import { MeusAnunciosInteresseListaComponent } from './components/meus-anuncios-interesse-lista/meus-anuncios-interesse-lista.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { InteresseDetailComponent } from './components/interesse-detail/interesse-detail.component';
import { AnalisarInteresseComponent } from './components/analisar-interesse/analisar-interesse.component';
import { BlockCopyPasteDirective } from '../shared/directives/block-copy-paste.directive';
import { CadastroAnuncioFotosComponent } from './components/cadastro-anuncio-fotos/cadastro-anuncio-fotos.component';
import { CadastroAnuncioPageComponent } from './components/cadastro-anuncio-page/cadastro-anuncio-page.component';
import { NgxGalleryModule } from 'ngx-gallery';
import { AnuncioNaoEncontradoComponent } from './components/anuncio-nao-encontrado/anuncio-nao-encontrado.component';
import { CadastroAnuncioDadosBasicosComponent } from './components/cadastro-anuncio-dados-basicos/cadastro-anuncio-dados-basicos.component';
import { CadastroAnuncioDetalhesComponent } from './components/cadastro-anuncio-detalhes/cadastro-anuncio-detalhes.component';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { CadastroAnuncioResumoComponent } from './components/cadastro-anuncio-resumo/cadastro-anuncio-resumo.component';
import { AnuncioDetailComponent } from './components/anuncio-detail/anuncio-detail.component';
import { SharedModule } from '../shared/shared.module';
import { AutorizarPublicacaoPageComponent } from './components/autorizar-publicacao-page/autorizar-publicacao-page.component';
import { AutorizarPublicacaoListaComponent } from './components/autorizar-publicacao-lista/autorizar-publicacao-lista.component';
import { MeusAnunciosPrivadoPageComponent } from './components/meus-anuncios-privado-page/meus-anuncios-privado-page.component';
import { MeusAnunciosGovernoPageComponent } from './components/meus-anuncios-governo-page/meus-anuncios-governo-page.component';
import { MeusAnunciosGovernoListaComponent } from './components/meus-anuncios-governo-lista/meus-anuncios-governo-lista.component';
import { MeusAnunciosPrivadoListaComponent } from './components/meus-anuncios-privado-lista/meus-anuncios-privado-lista.component';
import { CadastroAnuncioPrivadoDoacaoComponent } from './components/cadastro-anuncio-privado-doacao/cadastro-anuncio-privado-doacao.component';
import { CadastroAnuncioPrivadoIdentificacaoDoadorComponent } from './components/cadastro-anuncio-privado-identificacao-doador/cadastro-anuncio-privado-identificacao-doador.component';
import { CadastroAnuncioPrivadoFotosComponent } from './components/cadastro-anuncio-privado-fotos/cadastro-anuncio-privado-fotos.component';
import { CadastroAnuncioPrivadoPageComponent } from './components/cadastro-anuncio-privado-page/cadastro-anuncio-privado-page.component';
import { CadastroAnuncioPrivadoFavorecidoComponent } from './components/cadastro-anuncio-privado-favorecido/cadastro-anuncio-privado-favorecido.component';
import { CadastroAnuncioPrivadoResumoComponent } from './components/cadastro-anuncio-privado-resumo/cadastro-anuncio-privado-resumo.component';
import { RepublicarAnunciosPrivadoPageComponent } from './components/republicar-anuncios-privado-page/republicar-anuncios-privado-page.component';
import { RepublicarAnunciosPrivadoListaComponent } from './components/republicar-anuncios-privado-lista/republicar-anuncios-privado-lista.component';
import { ConsultarAnuncioPrivadoPageComponent } from './components/consultar-anuncio-privado-page/consultar-anuncio-privado-page.component';
import { ConsultarAnuncioPrivadoListaComponent } from './components/consultar-anuncio-privado-lista/consultar-anuncio-privado-lista.component';
import { AvisoResponsabilidadeConteudo } from './components/common/aviso-responsabilidade/aviso-responsabilidade.component';
import { ModalConfirmarDoacaoComponent } from './components/confirmar-doacao-anuncio-privado/modal-confirmar-doacao.component';
import { RelatorioAnuncioPageComponent } from './components/relatorio-anuncio-page/relatorio-anuncio-page.component';
import { RelatorioAnuncioListaComponent } from './components/relatorio-anuncio-lista/relatorio-anuncio-lista.component';
import { ModalFinalizarAutorizacaoDoacaoComponent } from './components/modal-finalizar-autorizacao-doacao/modal-finalizar-autorizacao-doacao.component';
import { FilterComponent } from './components/filter-anuncio/filter.component';
import { AnuncioItemNovoComponent } from './components/anuncio-item-novo/anuncio-item-novo.component';

@NgModule({
  imports: [
    CommonModule,
    AppPrimengModule,
    AnuncioRoutingModule,
    TagInputModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    SharedModule,
    NgxGalleryModule,
    ScrollToModule.forRoot(),
    ConfirmDialogModule,
    CurrencyMaskModule,
    SharedModule
  ],
  exports: [
  ],
  declarations: [
    AnuncioItemComponent,
    AnuncioItemNovoComponent,
    AnuncioFilterComponent,
    AnuncioListPageComponent,
    AnuncioDetailPageComponent,
    AutorizarPublicacaoPageComponent,
    AutorizarPublicacaoListaComponent,
    ConfirmarDoacaoListaComponent,
    ConfirmarDoacaoPageComponent,
    ConsultarAnuncioPrivadoPageComponent,
    ConsultarAnuncioPrivadoListaComponent,
    MeusAnunciosGovernoPageComponent,
    MeusAnunciosPrivadoPageComponent,
    RepublicarAnunciosPrivadoPageComponent,
    MeusAnunciosGovernoListaComponent,
    MeusAnunciosPrivadoListaComponent,
    RepublicarAnunciosPrivadoListaComponent,
    MeusInteressesListaComponent,
    MeusInteressesPageComponent,
    MeusAnunciosInteresseListaComponent,
    MeusAnunciosInteressePageComponent,
    TenhoInteresseComponent,
    InteresseDetailComponent,
    AnalisarInteresseComponent,
    BlockCopyPasteDirective,
    CadastroAnuncioFotosComponent,
    CadastroAnuncioPageComponent,
    AnuncioNaoEncontradoComponent,
    CadastroAnuncioDadosBasicosComponent,
    CadastroAnuncioDetalhesComponent,
    CadastroAnuncioResumoComponent,
    AnuncioDetailComponent,
    CadastroAnuncioPrivadoDoacaoComponent,
    CadastroAnuncioPrivadoIdentificacaoDoadorComponent,
    CadastroAnuncioPrivadoFotosComponent,
    CadastroAnuncioPrivadoPageComponent,
    CadastroAnuncioPrivadoFavorecidoComponent,
    CadastroAnuncioPrivadoResumoComponent,
    AvisoResponsabilidadeConteudo,
    ModalConfirmarDoacaoComponent,
    RelatorioAnuncioPageComponent,
    RelatorioAnuncioListaComponent,
    ModalFinalizarAutorizacaoDoacaoComponent,
    FilterComponent,
  ],
  providers: [
    ConfirmationService
  ]
})
export class AnuncioModule { }
