import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FiltroMeusAnunciosGoverno } from 'app/anuncio/models/filtro-meus-anuncios-governo.model';
import { AnunciosInternoPage } from '../common/anuncios-interno-page';
import { FiltroMeusAnunciosInteresse } from 'app/anuncio/models/filtro-meus-anuncios-interesse.model';
import { FiltroMeusInteresses } from 'app/anuncio/models/filtro-meus-interesses.model';

/**
 * Componente de filtro, pode ser usado em qualquer página.
 * os campos aparecem de acordo com o tipo de filtro passado.
 *
 */


@Component({
  selector: 'app-filter-anuncio',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent extends AnunciosInternoPage implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    this.configurarCalendario();
    //this.filtros = JSON.parse(JSON.stringify((this.filtrosProp)));
    this.filtros = this.filtrosProp;
    this.filtros.periodoInclusao = null;
    if( this.filtros instanceof FiltroMeusAnunciosGoverno){
      this.filtros.periodoExpiracao = null;
    }
    console.log("imprimindo filtros: ", this.filtros);

    this.popularSeparadoresTagInput();
  }


  // filtros aceitos, atualizar conforme necessário
  // FiltroMeusAnunciosGoverno | FiltroMeusAnunciosInteresse | FiltroMeusInteresses
  @Input("carregandoFiltros") carregandoFiltros!: boolean;
  @Input("filtros") filtrosProp!: FiltroMeusAnunciosGoverno | FiltroMeusAnunciosInteresse | FiltroMeusInteresses;
  //@Output() fechar = new EventEmitter<void>();
  @Output() aplicarFiltroEmmit = new EventEmitter<FiltroMeusAnunciosGoverno | FiltroMeusAnunciosInteresse | FiltroMeusInteresses>();
  @Output() exportarComFiltroEmmit = new EventEmitter<FiltroMeusAnunciosGoverno | FiltroMeusAnunciosInteresse| FiltroMeusInteresses>();

  filtros: FiltroMeusAnunciosGoverno | FiltroMeusAnunciosInteresse | FiltroMeusInteresses;
  show: boolean = false;

  public limparPeriodoInclusao(input):void {
    this.filtros.periodoInclusao = null;
    this.fecharCalendario(input);
  }

  public limparPeriodoExpiracao(input):void {
    if( this.filtros instanceof FiltroMeusAnunciosGoverno){
      this.filtros.periodoExpiracao = null;
      this.fecharCalendario(input);
    }

  }

  public limparFiltros():void {
    this.filtros.pesquisa = null;
    this.filtros.materialTiposSelecionados = [];
    this.filtros.materialCategoriasSelecionadas = [];

    this.filtros.periodoInclusao = null;

    this.filtros.anuncios = [];
    this.filtros.entidadesSelecionadas = [];

    if( this.filtros instanceof FiltroMeusInteresses){
      this.filtros.periodoAprovacao = null;
      this.filtros.ufsSelecionadas = [];
      this.filtros.anuncioInteresseSituacoesSelecionadas = [];
    }

    if( this.filtros instanceof FiltroMeusAnunciosGoverno){
      this.filtros.analisadoPorSelecionados = [];
      this.filtros.periodoExpiracao = null;
      this.filtros.materialSituacoesSelecionadas = [];
      this.filtros.ufsSelecionadas = [];
    }
    if( this.filtros instanceof FiltroMeusAnunciosInteresse){
      this.filtros.periodoInteresse = null;
    }
    this.aplicarFiltros();
  }

  fecharFilter() {
    this.show = !this.show;
  }

  temFiltros(){
    return Object.keys(this.filtros).some(key => this.filtros[key] != null);
  }

  //retorna true se o filtro possui itens no array de opçoes, caso não ele não deve ser mostrado
  mostrarFiltro(key: string){
    if(this.filtros[key] !== null && this.filtros[key] !== undefined && this.filtros[key].length > 0){
      return true;
    }
    return false;
  }

  //retorna o tipo de classe para ser usado no html para mostrar os campos corretos
  tipoClasse(){
    if( this.filtros instanceof FiltroMeusAnunciosGoverno){
      return 'MEUS-ANUNCIOS-GOVERNO';
    } else if( this.filtros instanceof FiltroMeusAnunciosInteresse){
      return 'MEUS-ANUNCIOS-INTERESSE';
    } else if( this.filtros instanceof FiltroMeusInteresses){
      return 'MEUS-INTERESSES';
    } else {
      return 'MEUS-ANUNCIOS-PRIVADO';
    }
  }

  exportar(){
    this.exportarComFiltroEmmit.emit(this.filtros);
  }

  aplicarFiltros(){
    console.log(this.filtros);
    this.aplicarFiltroEmmit.emit(this.filtros);
    this.fecharFilter();
  }

  abrirFilter(){
    this.show = !this.show;
  }
}
