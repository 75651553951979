<div *ngIf="parametrosExibirObject.length > 0" class="mb-2" style="margin-left: 30px;">
<p-card >
  <div class="ui-g ">

    <div class="d-flex align-items-center"><span>Filtros aplicados:</span></div>
    <p-message *ngFor="let item of parametrosExibirObject" severity="warn"   variant="outlined" [text]="item.chave +': '+ item.valor"></p-message>
  </div>
</p-card>
</div>
<div *ngIf="totalRegistros > 0">
  <div class="ui-g barraTotalOrdem">
    <div class="ui-g-6">
      <p-message severity="info" text="Total registros: {{totalRegistros}}"></p-message>
    </div>
    <div class="ui-g-6 barraOrdem">
      <p-dropdown [options]="ordenacoes" [(ngModel)]="ordem"
        (onChange)="aplicarOrdenacao($event)"></p-dropdown>
    </div>
  </div>

  <div class="ui-g list-anuncios d-flex">
      <reuse-anuncio-item-novo   *ngFor="let anuncio of anuncios"
        [anuncio]="anuncio" [origem]="MEUS_ANUNCIOS_GOVERNO">
      </reuse-anuncio-item-novo>
  </div>
  <p-paginator [rows]="tamanhoPagina" [totalRecords]="totalRegistros"
    (onPageChange)="paginar($event)" [(first)]="inicio"></p-paginator>
</div>
<div *ngIf="carregouDados && totalRegistros == 0" style="margin-left: 30px;">
  <p-message severity="warn" text="Nenhum registro encontrado."></p-message>
</div>
