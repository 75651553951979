import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnuncioService } from '../../services/anuncio.service';
import { ResultadoPesquisaInternaAnuncio } from '../../models/resultado-pesquisa-anuncio.model';
import { ParametrosMeusAnunciosGoverno } from '../../models/parametros-meus-anuncios-governo.model';
import { AnunciosInternoLista } from '../common/anuncios-interno-lista';
import { OrigemEnum } from 'app/shared/enum/origem.enum';
import { ChaveValor } from 'app/anuncio/models/comuns';

@Component({
  selector: 'reuse-meus-anuncios-governo-lista',
  templateUrl: './meus-anuncios-governo-lista.component.html',
  styleUrls: ['./meus-anuncios-governo-lista.component.scss']
})
export class MeusAnunciosGovernoListaComponent extends AnunciosInternoLista implements OnInit {

  MEUS_ANUNCIOS_GOVERNO = OrigemEnum.MEUS_ANUNCIOS_GOVERNO;
  parametrosExibir: string = '';
  parametrosExibirObject: ChaveValor[] =[];
  // indicador para verificar se os dados do backend já foram recebidos.
  carregouDados: boolean = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private anuncioService: AnuncioService) {
      super();
    }

  ngOnInit() {
    this.monitorarUrl();
    this.montarOpcoesOrdenacao();
    this.tamanhoPagina = 18;
  }

  /**
   * Monitora a url, sempre que houver mudanças nos parâmetros, realiza nova consulta de anúncios.
   */
  monitorarUrl() {
    this.route.queryParams.subscribe(
      params => {
        const parametros: ParametrosMeusAnunciosGoverno = this.anuncioService.obterParametrosMeusAnunciosGovernoUrl(params);
        this.ordem = parametros.ordem;
        this.montaExibirParametros(parametros);
        this.anuncioService.consultarMeusAnunciosGoverno(parametros).subscribe(
          (resultado: ResultadoPesquisaInternaAnuncio) => {
            this.anuncios = resultado.anuncios;
            this.totalRegistros = resultado.totalRegistros;
            this.inicio = resultado.inicio;
            this.carregouDados = true;
            window.scrollTo(0,0);
          });
      });
  }

  montaExibirParametros(parametros: ParametrosMeusAnunciosGoverno) {
    this.parametrosExibir = '';
    this.parametrosExibirObject = [];
    if (parametros.pesquisa) {
      this.parametrosExibir += 'Pesquisa: ' + parametros.pesquisa + ' ';
      this.parametrosExibirObject.push({chave: 'Pesquisa', valor: parametros.pesquisa});
    }
    if(parametros.anuncianteTipos && parametros.anuncianteTipos.length > 0) {
      this.parametrosExibir += 'Anunciante: ' + parametros.anuncianteTipos;
      this.parametrosExibirObject.push({chave: 'Anunciante', valor: parametros.anuncianteTipos});
    }
    if (parametros.materialTipos && parametros.materialTipos.length > 0) {
      this.parametrosExibir += 'Tipos: ' + parametros.materialTipos;
      this.parametrosExibirObject.push({chave: 'Tipos', valor: parametros.materialTipos});
    }
    if (parametros.materialCategorias && parametros.materialCategorias.length > 0) {
      this.parametrosExibir += 'Categorias: ' + parametros.materialCategorias;
      this.parametrosExibirObject.push({chave: 'Categorias', valor: parametros.materialCategorias});
    }
    if (parametros.materialSituacoes && parametros.materialSituacoes.length > 0) {
      this.parametrosExibir += 'Situações: ' + parametros.materialSituacoes;
      this.parametrosExibirObject.push({chave: 'Situações', valor: parametros.materialSituacoes});
    }
    if (parametros.ufs && parametros.ufs.length > 0) {
      this.parametrosExibir += 'UFs: ' + parametros.ufs;
      this.parametrosExibirObject.push({chave: 'UFs', valor: parametros.ufs});
    }
    if (parametros.onus && parametros.onus.length > 0) {
      this.parametrosExibir += 'Ônus: ' + parametros.onus;
      this.parametrosExibirObject.push({chave: 'Ônus', valor: parametros.onus});
    }
    if (parametros.periodoInclusao) {
      this.parametrosExibir += 'Período de Inclusão: ' + parametros.periodoInclusao + ' ';
      this.parametrosExibirObject.push({chave: 'Período de Inclusão', valor: parametros.periodoInclusao});
    }
    if (parametros.periodoExpiracao) {
      this.parametrosExibir += 'Período de Expiração: ' + parametros.periodoExpiracao + ' ';
      this.parametrosExibirObject.push({chave: 'Período de Expiração', valor: parametros.periodoExpiracao});
    }
    if (parametros.anuncios && parametros.anuncios.length > 0) {
      this.parametrosExibir += 'Anúncios: ' + parametros.anuncios;
      this.parametrosExibirObject.push({chave: 'Anúncios', valor: parametros.anuncios});
    }
    if (parametros.entidades && parametros.entidades.length > 0) {
      this.parametrosExibir += 'Entidades: ' + parametros.entidades;
      this.parametrosExibirObject.push({chave: 'Entidades', valor: parametros.entidades});
    }
    if (parametros.analisadoPor && parametros.analisadoPor.length > 0) {
      this.parametrosExibir += 'Analisado por: ' + parametros.analisadoPor;
      this.parametrosExibirObject.push({chave: 'Analisado por', valor: parametros.analisadoPor});
    }
  }

  /**
   * Consulta os meus anúncios, utilizando os parâmetros/filtros da url.
   */
  consultarAnunciosComParametrosUrl() {
    const parametrosUrl = this.route.snapshot.queryParams;
    const parametros: ParametrosMeusAnunciosGoverno = this.anuncioService.obterParametrosMeusAnunciosGovernoUrl(parametrosUrl);

    this.router.navigate([OrigemEnum.MEUS_ANUNCIOS_GOVERNO], {
      queryParams: {
        pesquisa: parametros.pesquisa,
        materialTipos: parametros.materialTipos,
        materialCategorias: parametros.materialCategorias,
        materialSituacoes: parametros.materialSituacoes,
        ufs: parametros.ufs,
        periodoInclusao: parametros.periodoInclusao,
        periodoExpiracao: parametros.periodoExpiracao,
        anuncios: parametros.anuncios,
        entidades: parametros.entidades,
        inicio: this.inicio,
        ordem: this.ordem
      }
    });
  }

}
