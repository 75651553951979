<div class="filter-anuncio">
  <div class="filter" style="max-width: 120px;" [title]="carregandoFiltros ? 'Carregando filtros disponíveis' : 'Filtros disponíveis'">
    <button
      *ngIf="carregandoFiltros"
      class="br-button block primary loading"
      type="button"
      title="Carregando os filtros disponíveis"
    >
      Carregando
    </button>
    <button
      *ngIf="!carregandoFiltros"
      title="Aplicar filtros"
      class="br-button block primary mb-2"
      type="button"
      (click)="abrirFilter()"
    >
      <span><i class="fa fa-filter" aria-hidden="true"></i>Filtros</span>
    </button>
    <button
    *ngIf="!carregandoFiltros"
      title="Exportar resultado da pesquisa"
      class="br-button block secondary"
      type="button"
      (click)="exportar()"
    >
      <i class="fas fa-file-export" aria-hidden="true"></i>Exportar
    </button>
  </div>
  <div
    *ngIf="show && temFiltros()"
    (keydown.enter)="aplicarFiltros()"
    [class]="show ? 'drawer-show' : ''"
  >
    <div id="drawer">
      <div class="data">
        <div class="header">
          <h3>Filtros</h3>
          <button
            pButton
            icon="fa fa-times"
            (click)="fecharFilter()"
            class="close"
          ></button>
        </div>
        <div class="content ui-g ui-fluid">
          <div class="ui-g-12">
            <label for="numeroAnuncio">Número do Anúncio</label>
            <tag-input
              id="numeroAnuncio"
              name="numeroAnuncio"
              [separatorKeyCodes]="separadores"
              [validators]="somenteNumero"
              placeholder="Número"
              [(ngModel)]="filtros.anuncios"
              secondaryPlaceholder="Filtrar por Número"
              addOnBlur="true"
              theme="bootstrap"
            ></tag-input>
          </div>
          <div class="ui-g-12">
            <label for="pesquisa">Título/Descrição</label>
            <input
              [(ngModel)]="filtros.pesquisa"
              id="pesquisa"
              name="pesquisa"
              type="text"
              pInputText
              placeholder="Filtrar por Título ou Descrição"
              maxlength="200"
            />
          </div>
          <div *ngIf="mostrarFiltro('materialTipos')" class="ui-g-12">
            <label for="materialTipo">Tipo</label>
            <p-multiSelect
              id="materialTipo"
              name="materialTipo"
              defaultLabel="Todos"
              [(ngModel)]="filtros.materialTiposSelecionados"
              [options]="filtros.materialTipos"
              optionLabel="nome"
              dataKey="nome"
            ></p-multiSelect>
          </div>
          <div *ngIf="mostrarFiltro('materialCategorias')" class="ui-g-12">
            <label for="materialCategoria">Categoria</label>
            <p-multiSelect
              id="materialCategoria"
              name="materialCategoria"
              defaultLabel="Todos"
              [(ngModel)]="filtros.materialCategoriasSelecionadas"
              [options]="filtros.materialCategorias"
              optionLabel="nome"
              dataKey="nome"
            ></p-multiSelect>
          </div>
          <div
            *ngIf="mostrarFiltro('anuncioInteresseSituacoes')"
            class="ui-g-12"
          >
            <label for="situacao">Situação</label>
            <p-multiSelect
              id="situacao"
              name="situacao"
              defaultLabel="Todos"
              [(ngModel)]="filtros.anuncioInteresseSituacoesSelecionadas"
              [options]="filtros.anuncioInteresseSituacoes"
              optionLabel="nome"
              dataKey="nome"
            ></p-multiSelect>
          </div>
          <div *ngIf="mostrarFiltro('materialSituacoes')" class="ui-g-12">
            <label for="situacao">Situação do Material</label>
            <p-multiSelect
              id="situacao"
              name="situacao"
              defaultLabel="Todos"
              [(ngModel)]="filtros.materialSituacoesSelecionadas"
              [options]="filtros.materialSituacoes"
              optionLabel="nome"
              dataKey="nome"
            ></p-multiSelect>
          </div>
          <div *ngIf="mostrarFiltro('ufs')" class="ui-g-12">
            <label for="uf">UF</label>
            <p-multiSelect
              id="uf"
              name="uf"
              defaultLabel="Todos"
              [(ngModel)]="filtros.ufsSelecionadas"
              [options]="filtros.ufs"
              optionLabel="sigla"
              dataKey="sigla"
            ></p-multiSelect>
          </div>
          <div class="ui-g-12">
            <label for="periodoInclusao">Período de Inclusão</label>
            <p-calendar
              id="periodoInclusao"
              name="periodoInclusao"
              [(ngModel)]="filtros.periodoInclusao"
              selectionMode="range"
              readonlyInput="true"
              [locale]="pt"
              dateFormat="dd/mm/yy"
              [showIcon]="true"
              #periodoInclusao
            >
              <p-footer>
                <div
                  class="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all footer-datepicker"
                >
                  <p-button
                    icon="pi pi-check"
                    class="button-datapicker-ok"
                    (onClick)="fecharCalendario(periodoInclusao)"
                  >
                  </p-button>
                  <p-button
                    icon="pi pi-times"
                    class="button-datapicker-cancel"
                    (onClick)="limparPeriodoInclusao(periodoInclusao)"
                  ></p-button>
                </div>
              </p-footer>
            </p-calendar>
          </div>
          <div class="ui-g-12" *ngIf="tipoClasse() === 'MEUS-ANUNCIOS-GOVERNO'">
            <label for="periodoExpiracao">Período de Expiração</label>
            <p-calendar
              id="periodoExpiracao"
              name="periodoExpiracao"
              [(ngModel)]="filtros.periodoExpiracao"
              selectionMode="range"
              readonlyInput="true"
              [locale]="pt"
              dateFormat="dd/mm/yy"
              [showIcon]="true"
              #periodoExpiracao
            >
              <p-footer>
                <div
                  class="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all footer-datepicker"
                >
                  <p-button
                    icon="pi pi-check"
                    class="button-datapicker-ok"
                    (onClick)="fecharCalendario(periodoExpiracao)"
                  ></p-button>
                  <p-button
                    icon="pi pi-times"
                    class="button-datapicker-cancel"
                    (onClick)="limparPeriodoExpiracao(periodoExpiracao)"
                  ></p-button>
                </div>
              </p-footer>
            </p-calendar>
          </div>
          <div class="ui-g-12" *ngIf="tipoClasse() === 'MEUS-INTERESSES'">
            <label for="periodoAprovacao">Período de Aprovação</label>
            <p-calendar
              id="periodoAprovacao"
              name="periodoAprovacao"
              [(ngModel)]="filtros.periodoAprovacao"
              selectionMode="range"
              readonlyInput="true"
              [locale]="pt"
              dateFormat="dd/mm/yy"
              [showIcon]="true"
              #periodoAprovacao
            >
              <p-footer>
                <div
                  class="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all footer-datepicker"
                >
                  <p-button
                    icon="pi pi-check"
                    class="button-datapicker-ok"
                    (onClick)="fecharCalendario(periodoAprovacao)"
                  ></p-button>
                  <p-button
                    icon="pi pi-times"
                    class="button-datapicker-cancel"
                    (onClick)="limparPeriodoAprovacao(periodoAprovacao)"
                  ></p-button>
                </div>
              </p-footer>
            </p-calendar>
          </div>
          <div
            class="ui-g-12"
            *ngIf="
              mostrarFiltro('entidades') && tipoClasse() === 'MEUS-INTERESSES'
            "
          >
            <label for="entidade">Entidade do Anunciante</label>
            <p-multiSelect
              id="entidade"
              name="entidade"
              defaultLabel="Todos"
              [(ngModel)]="filtros.entidadesSelecionadas"
              [options]="filtros.entidades"
              optionLabel="nome"
              dataKey="nome"
            ></p-multiSelect>
          </div>
          <div
            *ngIf="
              mostrarFiltro('entidades') &&
              tipoClasse() === 'MEUS-ANUNCIOS-GOVERNO'
            "
            class="ui-g-12"
          >
            <label for="entidade">Entidade Interessada</label>
            <p-multiSelect
              id="entidade"
              name="entidade"
              defaultLabel="Todos"
              [(ngModel)]="filtros.entidadesSelecionadas"
              [options]="filtros.entidades"
              optionLabel="nome"
              dataKey="nome"
            ></p-multiSelect>
          </div>
          <div *ngIf="mostrarFiltro('analisadoPor')" class="ui-g-12">
            <label for="analisadoPor">Analisado por</label>
            <p-multiSelect
              id="analisadoPor"
              name="analisadoPor"
              defaultLabel="Todos"
              [(ngModel)]="filtros.analisadoPorSelecionados"
              [options]="filtros.analisadoPor"
              optionLabel="nome"
              dataKey="nome"
            ></p-multiSelect>
          </div>
        </div>
      </div>
      <div class="footer-actions">
        <div class="footer">
          <div class="filter-action">
            <button
            title="Limpar filtros"
            class="br-button block secondary"
            (click)="limparFiltros()"
            type="button"
          >
            <i class="fa fa-trash" aria-hidden="true"></i>Limpar
          </button>
            <button
              title="Aplicar filtros"
              class="br-button block primary mb-2"
              type="button"
              (click)="aplicarFiltros()"
            >
              <i class="fa fa-sliders" aria-hidden="true"></i>Aplicar Filtros
            </button>
          </div>
          <div class="filter-export mt-1">

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
